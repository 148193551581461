/* website: 3656-welland-cbg
 * created at 2022-01-18 17:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";
.widget-sr{
  &.groups__5071{
    .sr-topbar-login{
      background: #111111;
    }
  }
}